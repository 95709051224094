<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("purchaseOrder.purchaseOrderAction") }}</span
        >
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="purchaseOrderId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="purchaseOrderId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
        >
          *{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 175px"
              :class="
                purchaseOrderId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                purchaseOrderId && !originModel.title
                  ? `${purchaseOrderId}.`
                  : $t("route.addPurchaseOrder") || purchaseOrderId
                  ? originModel.title &&
                    `${purchaseOrderId}. ${originModel.title}`
                  : $t("route.addPurchaseOrder")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="purchaseOrderId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("purchaseOrder.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyPurchaseOrder"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-content-copy</v-icon
                      >{{ $t("common.copyDocument") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon
                      >{{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-file-document</v-icon
                      >{{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="selectedStatus == 2"
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialog = true), (delete_item = purchaseOrderId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text"
                        >mdi-delete-sweep-outline</v-icon
                      >{{ $t("common.delete") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="purchaseOrderId && accessRight.includes('create')"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !purchaseOrderItems[0].purchase_order_id ||
                    !valid ||
                    (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("order.saveAndCreate") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="saveAndCreate('supplierDeliveryNote')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-truck-delivery
                      </v-icon>
                      {{ $t("route.supplierDeliveryNote") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="saveAndCreate('supplierInvoice')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-note-text-outline
                      </v-icon>
                      {{ $t("route.supplierInvoice") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="purchaseOrderId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <!-- <LoginGoogle /> -->
                <CommonCalendar
                  :docId="purchaseOrderId"
                  docType="PurchaseOrder"
                  :model="model"
                />
              </v-list>
            </v-menu>
            <HtmlToPdf
              v-if="purchaseOrderId"
              class="me-2 mb-1"
              :disabled="
                purchaseOrderItems[0].purchase_order_id &&
                valid &&
                !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="originModel"
              type="PurchaseOrderPDF"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable && !saveOnlyCategoryStatus) ||
                (!!model.is_locked && model.user_id !== user.id) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="savePurchaseOrder"
            >
              {{ $t("order.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                (purchaseOrderId &&
                  Object.keys(purchaseOrderById).length <= 0) ||
                (copiedPurchaseOrder && copyFormLoading)
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("purchaseOrder.supplierDetails") }}
                      </h6>
                      <v-spacer />
                      <v-icon
                        v-if="supplier_id"
                        @click="openCustomerAdditionalDetailModel"
                        color="primary"
                        class="float-end"
                        >mdi-eye-outline</v-icon
                      >
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            cache-items
                            :readonly="isFieldReadable"
                            v-model="selectedCustomer"
                            :items="supplierList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onSupplierChange"
                            flat
                            :rules="supplierValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="documentNextAndPrevious()"
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditSupplierPage"
                            :search-input.sync="supplierSearch"
                            :label="$t('purchaseOrder.supplier')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="supplierDialog = true"
                                >mdi-magnify</v-icon
                              >
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditSupplierPage">{{
                                `${data.item.id}. ${data.item.name_1}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasSupplierNextPage"
                                v-intersect="infiniteScroll"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.supplier_name"
                            :label="$t('purchaseOrder.supplierName')"
                            :rules="supplierNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.address"
                            :label="$t('purchaseOrder.address')"
                            :rules="[
                              lessThanValidation(
                                $t('purchaseOrder.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.town"
                            :label="$t('purchaseOrder.town')"
                            :rules="[
                              lessThanValidation(
                                $t('purchaseOrder.town'),
                                $t('common.less'),
                                250,
                                model.town
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("purchaseOrder.purchaseOrderDetail") }}
                      </h6>
                      <v-spacer />
                      <h6 class="font-weight-regular text-end">
                        {{
                          profileById.date_format && model.date_time_printed
                            ? this.formatDateDDMMYYYY(
                                model.date_time_printed.split(" ")[0]
                              ) +
                              " " +
                              model.date_time_printed.split(" ")[1]
                            : model.date_time_printed
                        }}
                      </h6>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            dense
                            :readonly="isFieldReadable"
                            v-model="model.title"
                            :label="$t('proforma.field.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.quotation_no"
                            :label="$t('purchaseOrder.quotationNo')"
                            :rules="quotationNoRequired"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="orderDateFormat"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.order_date"
                                :label="$t('purchaseOrder.orderDate')"
                                @blur="
                                  orderDateFormat = profileById.date_format
                                    ? model.order_date
                                    : parseDateYYYYMMDD(model.order_date)
                                "
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="orderDateFormat"
                              @change="
                                $refs.startDateMenu.save(orderDateFormat)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="model.order_date"
                            :label="$t('purchaseOrder.orderDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="orderValideDateFormat"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            nudge-left="120px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.req_delivery_date"
                                :label="
                                  $t('purchaseOrder.requiredDeliveryDate')
                                "
                                @blur="
                                  orderValideDateFormat =
                                    profileById.date_format
                                      ? parseDateYYYYMMDD(
                                          model.req_delivery_date
                                        )
                                      : model.req_delivery_date
                                "
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              elevation="240"
                              :min="orderDateFormat ? orderDateFormat : ''"
                              v-model="orderValideDateFormat"
                              @change="
                                $refs.endDateMenu.save(orderValideDateFormat)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.endDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="model.req_delivery_date"
                            :label="$t('purchaseOrder.requiredDeliveryDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="endDateMenu2"
                            v-model="endDateMenu2"
                            :close-on-content-click="false"
                            :return-value.sync="actualValidDateFormat"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            nudge-left="120px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.act_delivery_date"
                                :label="$t('purchaseOrder.actualDeliveryDate')"
                                @blur="
                                  actualValidDateFormat =
                                    profileById.date_format
                                      ? parseDateYYYYMMDD(
                                          model.act_delivery_date
                                        )
                                      : model.act_delivery_date
                                "
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              elevation="240"
                              :min="orderDateFormat ? orderDateFormat : ''"
                              v-model="actualValidDateFormat"
                              @change="
                                $refs.endDateMenu2.save(actualValidDateFormat)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.endDateMenu2.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="model.act_delivery_date"
                            :label="$t('purchaseOrder.actualDeliveryDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('purchaseOrder.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="employeeDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasEmployeeNextPage"
                                v-intersect="infiniteScrollOnEmployee"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="10" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("order.listOfItems") }}
                          </h6>
                        </v-col>
                        <v-col cols="2" class="text-end font-weight-regular">
                          <SuppliedQuantityDialog
                            :id="purchaseOrderId"
                            @suppliedQty="suppliedQty"
                          />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("order.no") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("order.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("order.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("order.quantity") }}
                                  </th>
                                  <th
                                    v-if="
                                      !purchaseOrderId ||
                                      (purchaseOrderId &&
                                        accessRight.includes('financial'))
                                    "
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("order.unitPrice") }}
                                  </th>
                                  <th
                                    v-if="
                                      !purchaseOrderId ||
                                      (purchaseOrderId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '50px' }"
                                  >
                                    {{ $t("order.lineDiscount") }}
                                  </th>
                                  <th
                                    v-if="!purchaseOrderId"
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    <span
                                      v-if="
                                        ($route.params.copiedModel &&
                                          $route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.purchaseOrderId &&
                                          purchaseOrderById.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.copiedModel &&
                                          !$route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.purchaseOrderId &&
                                          !purchaseOrderById.including_vat)
                                      "
                                    >
                                      {{ $t("order.total") }}
                                    </span>
                                    <span
                                      v-else-if="
                                        !profileById.purchase_order &&
                                        !$route.query.purchaseOrderId
                                      "
                                    >
                                      {{ $t("order.total") }}
                                    </span>
                                    <span
                                      v-else-if="
                                        profileById.purchase_order &&
                                        !$route.query.purchaseOrderId
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                  </th>
                                  <th
                                    v-else
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{
                                      !model.including_vat
                                        ? $t("order.total")
                                        : $t("company.field.totalIncludingVAT")
                                    }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("order.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(item, index) in purchaseOrderItems"
                                >
                                  <tr
                                    style="vertical-align: bottom"
                                    :key="index"
                                    :class="
                                      index + 1 === purchaseOrderItems.length
                                        ? 'newRow'
                                        : ''
                                    "
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                      <span
                                        v-if="!isFieldReadable"
                                        class="d-flex"
                                      >
                                        <product-item-select
                                          :selectedCustomer="selectedCustomer"
                                          :table="'PurchaseOrder'"
                                          :key="index"
                                          :itemId="item.item_id"
                                          :item="item"
                                          :index="index"
                                          @onProductChange="onProductChange"
                                        />
                                        <template>
                                          <v-icon
                                            class="mt-1"
                                            color="primary"
                                            dense
                                            v-if="!isFieldReadable"
                                            @click="
                                              (productDialog = true),
                                                (productDialogIndex = index)
                                            "
                                            :disabled="
                                              accessRight.length < 2 &&
                                              accessRight.includes('show')
                                            "
                                          >
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                      <span
                                        v-else
                                        @dblclick="
                                          redirectOnEditProductPage(
                                            item.item_id
                                          )
                                        "
                                        >{{ item.item_code }}</span
                                      >
                                    </td>
                                    <td>
                                      <v-textarea
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="[
                                          lessThanValidation(
                                            $t('order.itemDescription'),
                                            $t('common.less'),
                                            4000,
                                            item.item_description
                                          ),
                                        ]"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.quantity === 0">{{
                                          (item.quantity = null)
                                        }}</template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !purchaseOrderId ||
                                        (purchaseOrderId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price' + index"
                                        :ref="'unit_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template
                                          v-if="item.unit_price === 0"
                                          >{{
                                            (item.unit_price = null)
                                          }}</template
                                        >
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !purchaseOrderId ||
                                        (purchaseOrderId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="discountValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-slot:append>
                                          <v-icon
                                            class="mt-2"
                                            x-small
                                            color="primary"
                                            >mdi-percent-outline</v-icon
                                          >
                                        </template>
                                        <template
                                          v-if="item.line_discount === 0"
                                          >{{
                                            (item.line_discount = null)
                                          }}</template
                                        >
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !purchaseOrderId ||
                                        (purchaseOrderId &&
                                          accessRight.includes('financial'))
                                      "
                                      :class="
                                        locale === 'he'
                                          ? 'text-start'
                                          : 'text-end'
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.total === 0">{{
                                          (item.total = null)
                                        }}</template>
                                      </v-text-field>
                                    </td>
                                    <td class="text-center">
                                      <v-row>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            icon
                                            color="primary"
                                            small
                                            @click="addRow"
                                          >
                                            <v-icon color="primary"
                                              >mdi-plus</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show')) ||
                                              !item.selectedProduct
                                            "
                                            color="primary"
                                            x-small
                                            class="mt-1"
                                            @click="copyData(index)"
                                          >
                                            <v-icon color="primary"
                                              >mdi-content-copy</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-start">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="error"
                                            small
                                            @click="deleteRow(index)"
                                          >
                                            <v-icon color="error"
                                              >mdi-delete-sweep-outline</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :disabled="isFieldReadable"
                                    class="text-caption pt-0 mt-3"
                                  >
                                    <template v-slot:label>
                                      <div>
                                        {{ $t("purchaseOrder.status") }}
                                      </div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-select
                                    v-model="selectedGroup"
                                    :items="groupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                    :label="
                                      $t('purchaseOrder.purchaseOrderStatus')
                                    "
                                    @change="changeCategoryStatus"
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-autocomplete
                                    :class="
                                      relatedSupplierDeliveryItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedSupplierDeliveryItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="
                                      redirectOnEditSupplierDeliveryNotePage
                                    "
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('supplierInvoice.deliveryNo')"
                                  />
                                  <v-autocomplete
                                    :class="
                                      relatedSupplierInvoiceItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedSupplierInvoiceItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditSupplierInvoicePage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('supplierInvoice.invoiceNo')"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row>
                                <v-col cols="12">
                                  <v-textarea
                                    hide-details="auto"
                                    :readonly="isFieldReadable"
                                    dense
                                    v-model="model.remarks"
                                    :label="$t('purchaseOrder.remarks')"
                                    :rules="[
                                      lessThanValidation(
                                        $t('purchaseOrder.remarks'),
                                        $t('common.less'),
                                        1000,
                                        model.remarks
                                      ),
                                    ]"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !purchaseOrderId ||
                            (purchaseOrderId &&
                              accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{
                              $t("purchaseOrder.total")
                            }}</v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.total) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{
                                  $t("purchaseOrder.discount")
                                }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="discountValid"
                                    @input="purchaseOrderPriceCalculation"
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("purchaseOrder.afterDiscount") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.after_discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{
                                  $t("purchaseOrder.vat")
                                }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="purchaseOrderPriceCalculation"
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.vat) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{
                              $t("purchaseOrder.grandTotal")
                            }}</v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="purchaseOrderId"
        docType="Purchase"
        docMode="purchase"
        docItems="PurchaseOrderItem"
        docEmailSent="PurchaseOrderEmailSent"
      />
      <ProductDialog
        @onProductChange="onProductChange"
        v-if="supplier_id"
        :dialog.sync="productDialog"
        :productDialogIndex="productDialogIndex"
      />
      <CustomerDetail
        :model.sync="customerAdditionalDetail"
        :isFieldReadable="isFieldReadable"
        @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail"
        :customerDetailDialog.sync="customerDetailDialog"
      />
      <EmployeeDialog
        @onEmployeeChange="onEmployeeChange"
        :dialog.sync="employeeDialog"
      />
      <SupplierDialog
        @onSupplierChange="onSupplierChange"
        :dialog.sync="supplierDialog"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="purchaseOrderId"
        :docType="16"
      />
      <AttachedDocumentsDialog
        :docId="purchaseOrderId"
        docType="PurchaseOrder"
        :dialog.sync="attachedDocumentsDialog"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialog"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <SuppliedDialog :dialog.sync="suppliedDialogBox" />
      <RemainingSupplied
        :dialog.sync="remainingSuppliedDialogBox"
        :supplied="model.purchaseOrderItems"
        doc="purchaseOrder"
        :docId="model.id"
        :model="model"
        @reload="reload"
      />
      <CancelNotDialog :dialog.sync="cancelNotDialog" type="purchaseOrder" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changedFormData,
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
  todayDate,
  getAuxiliaryZtableValueByName,
} from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import ProductDialog from "@/components/moduleTableDialog/Dialog/ProductDialog";
import ProductItemSelect from "../../../components/ProductItemSelect.vue";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import RemarksDialog from "@/components/RemarksDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import SuppliedDialog from "@/components/SupliedDialog";
import RemainingSupplied from "@/components/RemainingSuppliedDialog";
import CancelNotDialog from "@/components/CancelNotDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import SuppliedQuantityDialog from "@/components/SuppliedQuantityDialog";
import CommonCalendar from "@/components/CommonCalendar";
import { validationMixin } from "@/mixins/validationMixin";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "PurchaseOrderAction",
  components: {
    FormSkeleton,
    Alert,
    ProductDialog,
    CustomerDetail,
    EmployeeDialog,
    ActivityLogTableDialog,
    SupplierDialog,
    HtmlToPdf,
    ProductItemSelect,
    RemarksDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    SuppliedDialog,
    RemainingSupplied,
    CancelNotDialog,
    NextAndPreviousCommon,
    SuppliedQuantityDialog,
    CommonCalendar,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: "",
      customerDetailDialog: false,
      alert: false,
      loading: false,
      valid: false,
      customerAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.0,
        discount: 0.0,
        after_discount: 0.0,
        vat: 0.0,
        grand_total: 0.0,
        date_time_printed: "",
      },
      originModel: {},
      selectedCustomer: null,
      supplierDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      endDateMenu: false,
      endDateMenu2: false,
      orderDateFormat: "",
      orderValideDateFormat: "",
      actualValidDateFormat: "",
      purchaseOrderItems: [
        {
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
        },
      ],
      supplier_id: null,
      customerPage: 1,
      supplierLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      productDialog: false,
      productDialogIndex: null,
      selectedStatus: 0,
      selectedProduct: [],
      copyFormLoading: true,
      nextId: null,
      prevId: null,
      remarksDialog: false,
      confirmationDialog: false,
      delete_item: null,
      groupValue: [],
      selectedGroup: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      isProgressCircular: false,
      suppliedDialogBox: false,
      remainingSuppliedDialogBox: false,
      relatedSupplierDeliveryItems: [],
      relatedSupplierInvoiceItems: [],
      cancelNotDialog: false,
      includeVatCharge: false,
      itemDiscount: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.profileById.date_format
        ? this.orderDateFormat
        : this.formatDateDDMMYYYY(this.orderDateFormat);
    },
    computedQuotationValideDateFormat() {
      return this.profileById.date_format
        ? this.orderValideDateFormat
        : this.formatDateDDMMYYYY(this.orderValideDateFormat);
    },
    computedActualDateValidFormat() {
      return this.profileById.date_format
        ? this.actualValidDateFormat
        : this.formatDateDDMMYYYY(this.actualValidDateFormat);
    },
    statusList() {
      if (this.model.status == 2) {
        return [
          { name: this.$t("order.field.open"), id: 0 },
          { name: this.$t("order.field.finished"), id: 1 },
          { name: this.$t("order.field.canceled"), id: 2 },
        ];
      } else {
        return [
          { name: this.$t("order.field.open"), id: 0 },
          { name: this.$t("order.field.finished"), id: 1 },
        ];
      }
    },
    quotationNoRequired() {
      return [
        (v) =>
          !v || /^[0-9]*$/.test(v) || this.$t("formRules.quotationNoInteger"),
        (v) =>
          !v ||
          (v && v.toString().length <= 8) ||
          this.$t("formRules.quotationNoValidMessage"),
      ];
    },
    discountValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 11) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("purchaseOrder.discount"),
            type: this.$t("common.less"),
            size: 11,
          }),
      ];
    },
    purchaseOrderId() {
      return this.$route.params.purchase_order_id;
    },
    copiedPurchaseOrder() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      purchaseOrderById: "purchaseOrder/purchaseOrderById",
      locale: "locale",
      profileById: "profile/profileById",
      employeeById: "employee/employeeById",
      supplierList: "supplier/supplierList",
      remarksList: "remarks/remarksList",
      supplierById: "supplier/supplierById",
      quotationById: "quotation/quotationById",
      employeeList: "employee/employeeList",
      purchaseOrderQuery: "purchaseOrder/purchaseOrderQuery",
      accessRight: "accessRight",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
  },
  watch: {
    orderDateFormat() {
      this.model.order_date = this.profileById.date_format
        ? this.orderDateFormat
        : this.formatDateDDMMYYYY(this.orderDateFormat);
    },
    orderValideDateFormat() {
      this.model.req_delivery_date = this.profileById.date_format
        ? this.orderValideDateFormat
        : this.formatDateDDMMYYYY(this.orderValideDateFormat);
    },
    actualValidDateFormat() {
      this.model.act_delivery_date = this.profileById.date_format
        ? this.actualValidDateFormat
        : this.formatDateDDMMYYYY(this.actualValidDateFormat);
    },
    profileById() {
      if (
        Object.keys(this.profileById).length > 0 &&
        !this.$route.params.purchase_order_id
      ) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
    },
    supplierLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("supplier/GetSupplier", {
          page: 1,
          limit: this.supplierLimit,
          order_by: "name_1|asc",
        });
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    purchaseOrderById() {
      if (Object.keys(this.purchaseOrderById).length > 0) {
        this.model = Object.assign({}, this.purchaseOrderById);
        if (this.model.order_date) {
          this.orderDateFormat = new Date(this.model.order_date)
            .toISOString()
            .substr(0, 10);
          this.model.order_date = this.profileById.date_format
            ? new Date(this.model.order_date).toISOString().substr(0, 10)
            : this.formatDateDDMMYYYY(this.model.order_date);
        }
        if (this.model.req_delivery_date) {
          this.orderValideDateFormat = new Date(this.model.req_delivery_date)
            .toISOString()
            .substr(0, 10);
          this.model.req_delivery_date = this.profileById.date_format
            ? new Date(this.model.req_delivery_date).toISOString().substr(0, 10)
            : this.formatDateDDMMYYYY(this.model.req_delivery_date);
        }
        if (this.model.act_delivery_date) {
          this.actualValidDateFormat = new Date(this.model.act_delivery_date)
            .toISOString()
            .substr(0, 10);
          this.model.act_delivery_date = this.profileById.date_format
            ? new Date(this.model.act_delivery_date).toISOString().substr(0, 10)
            : this.formatDateDDMMYYYY(this.model.act_delivery_date);
        }
        if (!this.$route.params.copiedModel) {
          this.relatedSupplierDeliveryItems =
            this.model.relatedSupplierDeliveryItems.filter(
              (item) => item.related_model_id
            );
          this.relatedSupplierInvoiceItems =
            this.model.relatedSupplierInvoiceItems.filter(
              (item) => item.related_model_id
            );
          this.selectedStatus = this.model.status;
        } else {
          this.model.status = 0;
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "PurchaseOrder",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    const self = this;
    setInterval(self.getNow, 1000);
    // More than one user
    window.addEventListener("beforeunload", this.handler);

    if (this.$route.params.purchase_order_id) {
      await this.$store.dispatch(
        "purchaseOrder/GetPurchaseOrderById",
        this.$route.params.purchase_order_id
      );
      if (!this.model.is_locked && this.$route.params.purchase_order_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "PurchaseOrder",
          id: this.$route.params.purchase_order_id,
          is_locked: true,
        });
      }
    }
    if (this.purchaseOrderId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 16,
        where_and: "document_id|" + this.purchaseOrderId,
      });
    }
  },
  async mounted() {
    this.onMounteUpdated();
    this.selectedEmployee = this.user.name;
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "PurchaseOrder",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.supplier_id = null;
    this.$store.commit("supplier/SET_SUPPLIER_BY_ID", {});
    this.$store.commit("purchaseOrder/SET_PURCHASE_ORDER_BY_ID", {});
    this.$store.commit("supplier/SET_SUPPLIER", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "PurchaseOrder",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (this.$route.params.copiedModel) {
        this.model = Object.assign(
          {},
          this.model,
          this.$route.params.copiedModel
        );
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.selectedGroup = null;
        this.model.status_one = null;
        this.copyFormLoading = false;
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.supplier_id = this.model.supplier_id;
        this.selectedCustomer = {
          id: this.model.supplier_id,
          name_1: this.model.supplier.name_1,
        };
        if (this.model.vat_percentage) {
          this.includeVatCharge = true;
        } else {
          this.includeVatCharge = false;
        }
        this.model.supplier_id = this.model.supplier.id;
        if (this.model.purchaseOrderItems.length < 1) {
          this.purchaseOrderItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.purchaseOrderItems = [];
        }
        this.model.status = 0;
        await this.$store
          .dispatch(
            "purchaseOrder/GetPurchaseOrderById",
            this.$route.params.copiedModel.id
          )
          .then((res) => {
            if (res) {
              this.model.supplier_name = res.supplier?.name_1;
              this.model.address = res.supplier?.address_1;
              this.model.town = res.supplier?.town_1;
              this.model.vat_percentage = res.vat_percentage;
              this.model.title = res.title;
              this.model.supplier_id = res.supplier?.id;
              this.model.remarks = res.remarks;
              this.selectedGroup = res.status_one;
            }
          });
        this.orderDateFormat = new Date().toISOString().substr(0, 10);
        this.model.order_date = this.profileById.date_format
          ? new Date().toISOString().substring(0, 10)
          : this.formatDateDDMMYYYY(this.model.order_date);
        this.model.total = 0.0;
        this.model.purchaseOrderItems.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          delete element["purchase_order_id"];
          delete element["supplied"];
          delete element["remainingQuantity"];
          delete element["inventory"];
          delete element["id"];
          let unit_price_change = this.numberWithCommas(
            element["unit_price"],
            "noFloat"
          );
          let total_change = this.numberWithCommas(element["total"], "noFloat");
          if (element["unit_price"] && !element["quantity"]) {
            element = Object.assign({}, { ...element, quantity: 1 });
          }
          this.purchaseOrderItems.push(
            Object.assign(
              {},
              {
                ...element,
                total: total_change,
                unit_price: unit_price_change,
              },
              {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              }
            )
          );
        });
        if (this.model.status === 0) {
          this.$nextTick(() => {
            this.$refs.form.validate();
          });
        }
        this.purchaseOrderPriceCalculation();
      } else if (this.$route.params.purchase_order_id) {
        this.documentNextAndPrevious();
        await this.$store.dispatch(
          "purchaseOrder/GetPurchaseOrderById",
          this.$route.params.purchase_order_id
        );
        this.model.supplier_id
          ? this.$store.dispatch(
              "supplier/GetSupplierById",
              this.model.supplier_id
            )
          : "";
        this.selectedGroup = this.model.status_one;
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.supplier_id = this.model?.supplier_id;
        this.selectedCustomer = {
          id: this.model?.supplier_id,
          name_1: this.model.supplier?.name_1,
          fixed_discount: this.model.supplier?.fixed_discount,
        };
        this.includeVatCharge = this.model?.supplier?.vat_charge;
        this.model.supplier_id = this.model?.supplier?.id;
        this.selectedStatus = this.model.status;
        if (this.model.purchaseOrderItems.length < 1) {
          this.purchaseOrderItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.purchaseOrderItems = [];
        }
        this.model.purchaseOrderItems.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          let unit_price_change = this.numberWithCommas(
            element["unit_price"],
            "noFloat"
          );
          let total_change = this.numberWithCommas(element["total"], "noFloat");
          if (element["unit_price"] && !element["quantity"]) {
            element = Object.assign({}, { ...element, quantity: 1 });
          }
          this.purchaseOrderItems.push(
            Object.assign(
              {},
              {
                ...element,
                total: total_change,
                unit_price: unit_price_change,
              },
              {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              }
            )
          );
        });

        if (this.model.status === 0) {
          this.$refs.form.validate();
          this.purchaseOrderPriceCalculation();
        }
      } else {
        this.orderDateFormat = new Date().toISOString().substr(0, 10);
        this.model.order_date = this.profileById.date_format
          ? new Date().toISOString().substring(0, 10)
          : this.formatDateDDMMYYYY(this.model.order_date);
      }
      this.$store.dispatch("profile/GetCompanyById");
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(21);
      if (Object.keys(tableNameValue).length > 0) {
        this.groupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    changeCategoryStatus() {
      if (this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.purchaseOrderId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "purchase_orders",
            customer_id: customerId,
            document_id: Number(this.purchaseOrderId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.purchaseOrderId) {
          let docPagination = await self.$store.dispatch(
            "GetDocumentPagination",
            {
              type: "purchase_orders",
              customer_id: customerId,
              document_id: Number(self.purchaseOrderId),
            }
          );
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    copyPurchaseOrder() {
      this.originModel?.purchaseOrderItems?.forEach((element) => {
        delete element.quotation_item_id;
        delete element.year;
      });
      let copyModel = this.originModel;
      this.$router.push({
        name: "addPurchaseOrder",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    callBackFunction() {
      this.$router.push({
        name: "PurchaseOrder",
        query: {
          ...this.purchaseOrderQuery,
        },
      });
    },
    onProductDoubleClick(index) {
      this.productDialog = true;
      this.productDialogIndex = index;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({ path: "/purchaseOrder/PurchaseOrderAction/" + id });
      } else {
        return false;
      }
    },
    redirectOnEditProductPage(itemId) {
      if (itemId) {
        this.$router.push({ path: "/item/ItemAction/" + itemId });
      } else {
        return false;
      }
    },
    redirectOnEditSupplierPage() {
      if (this.selectedCustomer) {
        this.$router.push({
          path: "/supplier/SupplierAction/" + this.selectedCustomer.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditEmployeePage() {
      if (this.selectedEmployee && this.selectedEmployee.id) {
        this.$router.push({
          path: "/employee/EmployeeAction/" + this.selectedEmployee.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditSupplierDeliveryNotePage(item) {
      if (item) {
        this.$router.push({
          path:
            "/supplierDeliveryNote/supplierDeliveryNoteAction/" +
            item.related_model_id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditSupplierInvoicePage(item) {
      if (item) {
        this.$router.push({
          path:
            "/supplierInvoice/supplierInvoiceAction/" + item.related_model_id,
        });
      } else {
        return false;
      }
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no =
        this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no =
        this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.purchase_order_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch("purchaseOrder/EditPurchaseOrder", {
          purchase_order_id: this.$route.params.purchase_order_id,
          purchaseOrder: { ...this.customerAdditionalDetail, main_number: 16 },
        });
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.model.date_time_printed = dateTime;
    },
    suppliedQty() {
      this.remainingSuppliedDialogBox = true;
    },
    reload(params) {
      this.model.status = params;
      this.selectedStatus = this.model.status;
      this.$store.dispatch("purchaseOrder/GetPurchaseOrderById", this.model.id);
    },
    async onSupplierChange(customer) {
      if (
        customer &&
        typeof customer === "object" &&
        Object.keys(customer).length > 0
      ) {
        let customerModel = {};
        this.selectedCustomer = {
          id: customer.id,
          name_1: customer?.name_1,
          fixed_discount: customer?.fixed_discount,
        };
        this.selectedEmployee = null;
        this.model.employee_id = null;
        this.model.supplier_id = customer.id;
        this.includeVatCharge = customer.vat_charge;
        this.supplier_id = customer.id;
        this.model.vat_percentage = this.profileById.vat_percentage;
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.supplier_name = customer?.name_1;
          if (customer.employee_id) {
            customer.employee_id
              ? await this.$store.dispatch(
                  "employee/GetEmployeeById",
                  customer.employee_id
                )
              : "";
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = customer.employee_id;
          } else {
            this.selectedEmployee = this.user.name;
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
        } else {
          this.model.supplier_name = "";
          customerModel.authorized_dealer_no = "";
          customerModel.email = "";
          customerModel.address = "";
          customerModel.town = "";
          customerModel.country = "";
          customerModel.phone_1 = "";
          customerModel.phone_2 = "";
          customerModel.cellular = "";
          customerModel.fax = "";
        }
        this.model = Object.assign({}, this.model, customerModel);
        if (this.model.status === 0) {
          this.$refs.form.validate();
        }
      } else {
        this.selectedCustomer = null;
        this.model.supplier_id = null;
      }
      this.purchaseOrderPriceCalculation();
    },
    itemPriceCalculation(index) {
      const decimalPoints = this.profileById.decimal_points || 2;
      let item = this.purchaseOrderItems[index];
      let checkUnitPrice = item.unit_price;
      if (checkUnitPrice && this.$refs["unit_price" + index]) {
        const pos =
          this.$refs["unit_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkUnitPrice.length !=
          this.numberWithCommas(
            this.numberFromCommas(item.unit_price),
            "noFloat"
          ).length
        ) {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }

      // price format of total price value
      let checkTotal = item.total;
      if (checkTotal && this.$refs["total_price" + index]) {
        const pos =
          this.$refs["total_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkTotal.length !=
          this.numberWithCommas(this.numberFromCommas(item.total), "noFloat")
            .length
        ) {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }
      let unitPriceNumber = parseFloat(this.numberFromCommas(item.unit_price));
      this.purchaseOrderItems[index].unit_price = this.numberWithCommas(
        unitPriceNumber.toFixed(decimalPoints),
        "noFloat"
      );
      this.purchaseOrderItems[index].total = this.numberWithCommas(
        this.numberFromCommas(item.total),
        "noFloat"
      );
      this.$nextTick(() => {
        if (this.$refs.form && this.$refs["unit_price" + index][0].validate()) {
          item.unit_price = unitPriceNumber.toFixed(decimalPoints);
        }
        if (
          this.$refs.form &&
          this.$refs["total_price" + index][0].validate()
        ) {
          item.total = this.numberFromCommas(item.total);
        }
        if (parseFloat(item.unit_price)) {
          item.total =
            parseFloat(item.quantity) && parseFloat(item.unit_price)
              ? (
                  parseFloat(item.quantity) * parseFloat(item.unit_price)
                ).toFixed(2)
              : parseFloat(item.unit_price)
              ? parseFloat(item.unit_price).toFixed(2)
              : (0).toFixed(2);

          // item total calculation with discount
          item.total = parseFloat(item.line_discount)
            ? (
                parseFloat(item.total) -
                (parseFloat(item.total) * parseFloat(item.line_discount)) / 100
              ).toFixed(2)
            : parseFloat(item.total).toFixed(2);

          this.purchaseOrderItems[index].unit_price = this.numberWithCommas(
            unitPriceNumber.toFixed(decimalPoints),
            "noFloat"
          );

          this.purchaseOrderItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );

          if (this.purchaseOrderItems[index].unit_price && !item.quantity) {
            item.quantity = 1;
          }
        } else {
          this.purchaseOrderItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );
        }

        this.purchaseOrderItems[index] = item;

        // total calculation on quotation
        this.purchaseOrderPriceCalculation();
      });
    },
    purchaseOrderPriceCalculation() {
      // total item's calculation
      let self = this;
      if (!this.purchaseOrderId && this.$route.params.copiedModel) {
        if (this.$route.params.copiedModel.including_vat) {
          this.model.total =
            this.purchaseOrderItems.length > 0
              ? this.purchaseOrderItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.purchaseOrderItems.length > 0
              ? this.purchaseOrderItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      } else {
        if (
          (!this.purchaseOrderId && this.profileById.purchase_order) ||
          (this.purchaseOrderId && this.model.including_vat)
        ) {
          this.model.total =
            this.purchaseOrderItems.length > 0
              ? this.purchaseOrderItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.purchaseOrderItems.length > 0
              ? this.purchaseOrderItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      }

      // discount amount calculation
      this.model.discount_percentage = parseFloat(
        this.model.discount_percentage
      )
        ? parseFloat(this.model.discount_percentage).toFixed(2)
        : null;
      this.model.discount = parseFloat(this.model.discount_percentage)
        ? (
            (parseFloat(this.model.total) *
              parseFloat(this.model.discount_percentage)) /
            100
          ).toFixed(2)
        : (0).toFixed(2);

      // total amount after discount calculation
      this.model.after_discount = parseFloat(this.model.discount)
        ? (
            parseFloat(this.model.total) - parseFloat(this.model.discount)
          ).toFixed(2)
        : parseFloat(this.model.total).toFixed(2);

      this.model.vat_percentage = parseFloat(this.model.vat_percentage)
        ? parseFloat(this.model.vat_percentage)
        : null;
      this.model.vat =
        parseFloat(this.model.vat_percentage) && this.includeVatCharge
          ? (
              (parseFloat(this.model.after_discount) *
                parseFloat(this.model.vat_percentage)) /
              100
            ).toFixed(2)
          : (0).toFixed(2);

      // grand total amount calculation
      this.model.grand_total = parseFloat(this.model.vat)
        ? (
            parseFloat(this.model.after_discount) + parseFloat(this.model.vat)
          ).toFixed(2)
        : parseFloat(this.model.after_discount).toFixed(2);

      // after vat grand total rounding if total rounding is true
      if (this.profileById.total_rounding) {
        let total = this.model.total;
        let discount = this.model.discount;
        let vatPercentage = this.model.vat_percentage;
        let grandTotal = parseInt(this.model.grand_total);
        let roundingDiff = this.model.grand_total - grandTotal;
        let findRoundNumber = parseFloat(
          roundingDiff / (1 + vatPercentage / 100)
        ).toFixed(2);
        if (findRoundNumber <= 0.5) {
          this.model.discount = (
            parseFloat(discount) + parseFloat(findRoundNumber)
          ).toFixed(2);
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          this.model.vat = (
            parseFloat(grandTotal) - parseFloat(this.model.after_discount)
          ).toFixed(2);
          this.model.grand_total = parseFloat(grandTotal).toFixed(2);
        } else {
          let roundingDiff = parseFloat(
            (grandTotal + 1 - this.model.grand_total) * -1
          ).toFixed(2);
          this.model.discount = (
            parseFloat(discount) +
            parseFloat(roundingDiff / (1 + vatPercentage / 100))
          ).toFixed(2);
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          this.model.vat = (
            parseFloat(grandTotal) +
            1 -
            parseFloat(this.model.after_discount)
          ).toFixed(2);
          this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
        }
      }
    },
    addRow() {
      this.purchaseOrderItems.push({
        index: this.purchaseOrderItems.length,
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    copyData(i) {
      delete this.purchaseOrderItems[i].id;
      delete this.purchaseOrderItems[i].purchase_order_id;
      delete this.purchaseOrderItems[i].created_at;
      delete this.purchaseOrderItems[i].updated_at;
      delete this.purchaseOrderItems[i].line_number;
      delete this.purchaseOrderItems[i].supplied;
      this.purchaseOrderItems.push({ ...this.purchaseOrderItems[i] });
    },
    deleteRow(index) {
      this.selectedProduct.splice(index, 1);
      this.purchaseOrderItems.splice(index, 1);
      this.purchaseOrderPriceCalculation();
    },
    async onProductChange(item, index, type) {
      if (item && type === "dropdown") {
        this.purchaseOrderItems[index].index = index;
        this.purchaseOrderItems[index].selectedProduct = {
          item_id: item.id,
          item_code: item.item_code,
        };

        if (!item?.supplierPrice) {
          this.purchaseOrderItems[index].unit_price = item.unit_price;
        }

        this.purchaseOrderItems[index].item_id = item.id;
        this.purchaseOrderItems[index].quantity = item.quantity;
        if (this.selectedCustomer?.fixed_discount) {
          this.purchaseOrderItems[index].line_discount =
            this.selectedCustomer?.fixed_discount;
        } else if (item.supplierDiscount) {
          this.purchaseOrderItems[index].line_discount = item.supplierDiscount;
        } else if (item.supplierPrice) {
          this.purchaseOrderItems[index].unit_price = item.supplierPrice;
        } else if (item.fixed_discount) {
          this.purchaseOrderItems[index].line_discount = item.fixed_discount;
        } else {
          this.purchaseOrderItems[index].line_discount = null;
        }
        this.purchaseOrderItems[index].item_code = item.item_code;
        this.purchaseOrderItems[index].item_description = item.item_description;
        this.selectedProduct[index] = this.purchaseOrderItems[index];
        this.$refs.form.validate();
        this.itemPriceCalculation(index);
      } else {
        if (item && Object.keys(item).length > 0) {
          await this.$store
            .dispatch("product/GetSupplierItemDiscountOrPrice", {
              supplier_id: this.selectedCustomer.id,
              item_id: item.id,
            })
            .then(async (res) => {
              this.itemDiscount = await res;
            });

          this.purchaseOrderItems[index].index = index;
          this.purchaseOrderItems[index].selectedProduct = {
            item_id: item.id,
            item_code: item.item_code,
          };

          if (!this.itemDiscount && !this.itemDiscount.supplierPrice) {
            this.purchaseOrderItems[index].unit_price = item.purchase_price;
          }

          this.purchaseOrderItems[index].item_id = item.id;
          this.purchaseOrderItems[index].quantity = item.quantity;
          if (this.selectedCustomer?.fixed_discount) {
            this.purchaseOrderItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (this.itemDiscount && this.itemDiscount.supplierDiscount) {
            this.purchaseOrderItems[index].line_discount =
              this.itemDiscount.supplierDiscount;
          } else if (this.itemDiscount && this.itemDiscount.supplierPrice) {
            this.purchaseOrderItems[index].unit_price =
              this.itemDiscount.supplierPrice;
          } else {
            this.purchaseOrderItems[index].line_discount = null;
          }
          this.purchaseOrderItems[index].item_code = item.item_code;
          this.purchaseOrderItems[index].item_description = item.description;
          this.selectedProduct[index] = this.purchaseOrderItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        } else {
          this.purchaseOrderItems[index].index = index;
          this.purchaseOrderItems[index].selectedProduct = null;
          this.purchaseOrderItems[index].unit_price = null;
          this.purchaseOrderItems[index].item_id = null;
          this.purchaseOrderItems[index].quantity = null;
          this.purchaseOrderItems[index].line_discount = null;
          this.purchaseOrderItems[index].item_code = null;
          this.purchaseOrderItems[index].item_description = null;
          this.selectedProduct[index] = this.purchaseOrderItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        }
      }
    },
    async savePurchaseOrder() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        let purchaseOrderItems;
        if (!this.$route.params.purchase_order_id) {
          purchaseOrderItems = this.purchaseOrderItems.filter((item) => {
            if (item.item_id || item.id) {
              delete item["selectedProduct"];
              delete item["index"];
              item.quantity = item.quantity === "" ? null : item.quantity;
              item.line_discount =
                item.line_discount === "" ? null : item.line_discount;
              item.unit_price =
                this.numberFromCommas(item.unit_price) === ""
                  ? null
                  : this.numberFromCommas(item.unit_price);
              item.total =
                this.numberFromCommas(item.total) === ""
                  ? null
                  : this.numberFromCommas(item.total);
              return item;
            }
          });
        } else {
          purchaseOrderItems = this.purchaseOrderItems.filter((item) => {
            if (item.item_id || item.id) {
              delete item["selectedProduct"];
              delete item["index"];
              item.quantity = item.quantity === "" ? null : item.quantity;
              item.line_discount =
                item.line_discount === "" ? null : item.line_discount;
              item.unit_price =
                this.numberFromCommas(item.unit_price) === ""
                  ? null
                  : this.numberFromCommas(item.unit_price);
              item.total =
                this.numberFromCommas(item.total) === ""
                  ? null
                  : this.numberFromCommas(item.total);
              return item;
            }
          });
        }
        this.model.data = purchaseOrderItems;
        this.$nextTick();
        this.model.order_date = this.profileById.date_format
          ? this.model.order_date
          : this.parseDateYYYYMMDD(this.model.order_date);
        this.model.req_delivery_date = this.profileById.date_format
          ? this.model.req_delivery_date
          : this.parseDateYYYYMMDD(this.model.req_delivery_date);
        this.model.act_delivery_date = this.profileById.date_format
          ? this.model.act_delivery_date
          : this.parseDateYYYYMMDD(this.model.act_delivery_date);

        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.status_one = this.selectedGroup.id;
        }

        if (
          this.$route.query.purchaseOrderId &&
          !this.model.including_vat &&
          !this.model.id
        ) {
          this.model.including_vat = this.purchaseOrderById.including_vat
            ? 1
            : 0;
        } else if (!this.model.id) {
          this.model.including_vat = this.profileById.purchase_order ? 1 : 0;
        }

        this.model.main_number = 16;

        if (this.$route.params.purchase_order_id) {
          let saveData = changedFormData(this.model, this.originModel);
          this.isProgressCircular = true;
          data = await this.$store
            .dispatch("purchaseOrder/EditPurchaseOrder", {
              purchase_order_id: this.$route.params.purchase_order_id,
              purchaseOrder: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.onMounteUpdated();
          }
          this.isProgressCircular = false;
        } else if (this.$route.params.copiedModel) {
          let copyModel = this.model;
          copyModel.data.forEach((element) => {
            delete element.quotation_item_id;
          });
          delete copyModel.employee;
          delete copyModel.created_at;
          delete copyModel.id;
          delete copyModel.updated_at;
          copyModel.including_vat = this.originModel.including_vat;
          data = await this.$store
            .dispatch("purchaseOrder/SetPurchaseOrder", copyModel)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/purchaseOrder/editPurchaseOrder/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        } else {
          data = await this.$store
            .dispatch("purchaseOrder/SetPurchaseOrder", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/purchaseOrder/editPurchaseOrder/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        }
      }
    },
    async saveAndCreate(type) {
      if (
        this.model &&
        this.model.purchaseOrderItems &&
        this.model.purchaseOrderItems.every(
          (ele) => ele.remainingQuantity === 0
        )
      ) {
        this.suppliedDialogBox = true;
      } else {
        try {
          await this.$store.dispatch("quotation/CheckRemainingQty", {
            data: this.model.purchaseOrderItems,
            document_type:
              type === "supplierDeliveryNote"
                ? "Supplier_Delivery_Note"
                : type === "supplierInvoice"
                ? "Supplier_Invoice"
                : undefined,
          });
          this.suppliedDialogBox = false;
          let data;
          if (this.$refs.form.validate() && !this.isFieldReadable) {
            this.loading = true;
            let purchaseOrderItems;
            if (!this.$route.params.purchase_order_id) {
              purchaseOrderItems = this.selectedProduct.filter((item) => {
                if (item.item_id || item.id) {
                  delete item["selectedProduct"];
                  delete item["index"];
                  delete item["delivery_date"];
                  item.quantity = item.quantity === "" ? null : item.quantity;
                  item.line_discount =
                    item.line_discount === "" ? null : item.line_discount;
                  item.unit_price =
                    this.numberFromCommas(item.unit_price) === ""
                      ? null
                      : this.numberFromCommas(item.unit_price);
                  item.total =
                    this.numberFromCommas(item.total) === ""
                      ? null
                      : this.numberFromCommas(item.total);
                  return item;
                }
              });
            } else {
              purchaseOrderItems = this.purchaseOrderItems.filter((item) => {
                if (item.item_id || item.id) {
                  delete item["selectedProduct"];
                  delete item["index"];
                  delete item["delivery_date"];
                  delete item["supplied"];
                  item.quantity = item.quantity === "" ? null : item.quantity;
                  item.line_discount =
                    item.line_discount === "" ? null : item.line_discount;
                  item.unit_price =
                    this.numberFromCommas(item.unit_price) === ""
                      ? null
                      : this.numberFromCommas(item.unit_price);
                  item.total =
                    this.numberFromCommas(item.total) === ""
                      ? null
                      : this.numberFromCommas(item.total);
                  return item;
                }
              });
            }
            this.model.data = purchaseOrderItems;
            this.$nextTick();
            if (
              this.selectedStatus &&
              Object.keys(this.selectedStatus).length > 0
            ) {
              this.model.status = this.selectedStatus.id;
            } else {
              this.model.status = this.selectedStatus;
            }
            if (
              this.selectedGroup &&
              Object.keys(this.selectedGroup).length > 0
            ) {
              this.model.status_one = this.selectedGroup.id;
            }
            this.model.main_number = 16;

            if (this.model.data.length > 0) {
              if (this.$route.params.purchase_order_id) {
                let saveData = changedFormData(this.model, this.originModel);
                this.isProgressCircular = true;
                data = await this.$store
                  .dispatch("purchaseOrder/EditPurchaseOrder", {
                    purchase_order_id: this.$route.params.purchase_order_id,
                    purchaseOrder: saveData,
                  })
                  .catch((e) => {
                    this.loading = false;
                    console.log(e);
                  });
                this.isProgressCircular = false;
              } else {
                data = await this.$store
                  .dispatch("purchaseOrder/SetPurchaseOrder", this.model)
                  .catch((e) => {
                    this.loading = false;
                    console.log(e);
                  });
              }
              if (data) {
                this.$refs.form.reset();
                this.$router.push("/purchaseOrder");
              }
            } else {
              this.message = "formRules.productItemAlert";
              this.alert = true;
              this.loading = false;
            }
            if (type === "supplierDeliveryNote") {
              this.$router.push({
                path: "/addSupplierDeliveryNote",
                query: {
                  purchaseOrderId: JSON.parse(data.config.data).data[0]
                    .purchase_order_id,
                  supplierId: this.model.supplier_id,
                },
              });
            } else if (type === "supplierInvoice") {
              this.$router.push({
                path: "/addSupplierInvoice",
                query: {
                  purchaseOrderId: JSON.parse(data.config.data).data[0]
                    .purchase_order_id,
                  supplierId: this.model.supplier_id,
                },
              });
            }
          } else if (this.isFieldReadable) {
            if (type === "supplierDeliveryNote") {
              this.$router.push({
                path: "/addSupplierDeliveryNote",
                query: {
                  purchaseOrderId: this.$route.params.purchase_order_id,
                  supplierId: this.model.supplier_id,
                },
              });
            } else if (type === "supplierInvoice") {
              this.$router.push({
                path: "/addSupplierInvoice",
                query: {
                  purchaseOrderId: this.$route.params.purchase_order_id,
                  supplierId: this.model.supplier_id,
                },
              });
            }
          } else {
            this.message = "formRules.formAlert";
            this.alert = true;
          }
        } catch (error) {
          console.log(error, "error");
          this.suppliedDialogBox = true;
        }
      }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store
        .dispatch(
          "purchaseOrder/DeletePurchaseOrderById",
          this.$route.params.purchase_order_id
        )
        .then((res) => {
          if (res.data.type && res.data.type === "delete") {
            this.cancelNotDialog = true;
            this.isProgressCircular = false;
          } else {
            this.$router
              .push(
                "/purchaseOrder/editPurchaseOrder/" +
                  this.$route.params.purchase_order_id
              )
              .catch(() => {});
            this.model.status = 2;
            this.originModel.status = 2;
            this.selectedStatus = 2;
            this.isProgressCircular = false;
          }
        });
    },
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>
